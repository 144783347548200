import React, { useEffect, useState } from 'react';
import StarRating from './StarRating';
import styled from 'styled-components/macro';
import { Col, Flex, MaskImg, Text } from '../Ui';
import Button from '../Ui/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineHeart, AiOutlineShoppingCart } from 'react-icons/ai';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { respondTo } from '../../theme/mixin';
import { ReactComponent as RemoveWishlistIcon } from '../../assets/images/removeWishlistIcon.svg';

import { KeyboardArrowDown, KeyboardArrowUp } from '@styled-icons/material';
import {
  addToWishlist,
  removeFromWishlist,
  addToCart,
  removeFromCart,
} from '../../store/actions/wishlist';
import { getSingleProduct, openOrderModal, closeOrderModal } from '../../store/actions/products';
import ImagesCarousel from '../imagesCarousel';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Techs from './Techs';
import customAxiosNm from '../../config/axios-refresh-token';
import { BsArrowRightCircle } from 'react-icons/bs';
import { numberWithDots } from '../../utils/utils';
import useWishlist from '../../hooks/useWishlist';
import useCart from '../../hooks/useCart';

const Container = styled.div`
  width: 100%;
  position: relative;
  border: ${({ border, theme }) => (border ? '1px solid ' + theme.borderCode : 'none')};
  border-radius: ${({ theme }) => theme.border_radius_generale_grande};
  padding: 7px;
  margin-bottom: ${({ bottom }) => bottom}px;

  .btns {
    svg {
      margin-left: 0;
      color: ${({ theme }) => theme.colore_testo_box};
    }
  }
`;

const Content = styled.div`
  margin-top: 15px;
  & > * + * {
    margin-top: 15px;
  }

  & > * {
    text-align: left;
  }

  .btns_mobile {
    display: block;

    ${respondTo.sm`
      display: none;
    `}
  }
`;

const AddToCart = styled(MaskImg)`
  width: 35px;
  height: 35px;
  margin: 0;
`;
const AddToWishlist = styled(AddToCart)``;

function ProductDetails({
  setpuntiAbbastanza,
  inLine,
  inSlider,
  product,
  inModal,
  horiz,
  setIsReviews,
  date,
  status,
  ...restProps
}) {
  const {
    id,
    description = '',
    catalog_id,
    title,
    main_features,
    ean,
    description_top,
    mpn,
    points,
    fee,
    images = [],
    rating,
    reviews,
    tech_data,
    is_salabam,
    cta,
  } = product || {};

  const dispatch = useDispatch();
  const isWishlist = useWishlist();
  const isCart = useCart();
  const wishlist = useSelector(state => state.user.user.wishlist);
  const cart = useSelector(state => state.user.user.cart);
  const { id: userId, score } = useSelector(state => state.user.user.userinfo || {});
  const pointsLabel = useSelector(state => state.app.config.points_label);
  const reviews_enabled = useSelector(state => state.app.config.reviews_enabled);
  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});
  const has_fee = useSelector(state => state.app.config.has_fee);

  const [isLoadingCart, setIsLoadingCart] = useState(false);
  const [isLoadingWish, setIsLoadingWish] = useState(false);
  const [isTech, setIsTech] = useState(false);

  const [inWishlist, setInWishlist] = useState(false);
  const [inCart, setInCart] = useState(false);
  const [numRating, setNumRating] = useState(0);
  const [salabamLoading, setSalabamLoading] = useState(false);
  const [accessToSalabam, setAccessToSalabam] = useState(false);
  const history = useHistory();
  const pathName = window.location.pathname;

  const addToWishlistHandler = async productId => {
    setIsLoadingWish(true);
    dispatch(closeOrderModal());
    await dispatch(addToWishlist(productId, catalog_id));
    setIsLoadingWish(false);
  };

  const removeFromWishlistHandler = async productId => {
    setIsLoadingWish(true);
    pathName === '/wishlist' && dispatch(closeOrderModal());
    await dispatch(removeFromWishlist(productId, catalog_id));

    setIsLoadingWish(false);
  };
  const moveToCartHandler = async productId => {
    setIsLoadingCart(true);
    if (score - points < 0) {
      setpuntiAbbastanza(true);
      setIsLoadingCart(false);
    } else {
      dispatch(addToCart(productId, catalog_id)).then(res => {
        !Boolean(res.error) && dispatch(closeOrderModal());
        !Boolean(res.error) && dispatch(removeFromWishlist(productId, catalog_id));
      });
      setIsLoadingCart(false);
    }
  };
  useEffect(() => {
    if (wishlist && product) {
      const exist = wishlist.find(el => el.id === product.id);

      if (exist) {
        setInWishlist(true);
      } else {
        setInWishlist(false);
      }
    }
  }, [wishlist, product]);

  useEffect(() => {
    if (cart && product) {
      const exist = cart.find(el => el.id === product.id);
      if (exist) {
        setInCart(true);
      } else {
        setInCart(false);
      }
    }
  }, [cart, product]);
  useEffect(() => {
    setAccessToSalabam(false);
  }, []);
  const handlerModal = () => {
    dispatch(getSingleProduct(id, catalog_id));
    product && dispatch(openOrderModal(product));
  };
  const removeCartHandler = async () => {
    setIsLoadingCart(true);
    pathName === '/cart' && dispatch(closeOrderModal());
    await dispatch(removeFromCart(id, catalog_id));
    setIsLoadingCart(false);
  };
  const addCartHandler = async () => {
    setIsLoadingCart(true);

    if (score - points < 0) {
      setpuntiAbbastanza(true);
      setIsLoadingCart(false);
    } else {
      dispatch(addToCart(id, catalog_id)).then(res => {
        setIsLoadingCart(false);
        !Boolean(res.error) && dispatch(closeOrderModal());
        // !Boolean(res.error) && history.push('/cart');
      });
    }
  };
  const moveToWishlistHandler = async productId => {
    setIsLoadingWish(true);
    dispatch(closeOrderModal());
    await dispatch(removeFromCart(productId, catalog_id));
    await dispatch(addToWishlist(productId, catalog_id));
    setIsLoadingWish(false);
  };
  const salabamHandler = () => {
    if (score < points) {
      setAccessToSalabam(true);
      return;
    }

    let endpoint = `/salabam/init`;
    setSalabamLoading(true);
    customAxiosNm
      .post(endpoint, {
        product_id: id,
        catalog_id,
      })
      .then(result => {
        if (result.status === 200) {
          setSalabamLoading(false);
          window.open(result.data.redirectTo, '_blank');
        }
      })
      .catch(error => {
        setSalabamLoading(false);
      });
  };
  return (
    <Container {...restProps}>
      <Text as='h4' size={20} bold text_box>
        {title}
      </Text>
      <Flex justify='space-between' align='center'>
        <Col width={70}>
          <ImagesCarousel images={images} />
        </Col>
        <Col width={30} className='sm_mt-20'>
          {ean && ean !== '0' && (
            <Flex wrap='nowrap' className='mb-10'>
              <Text bold upper as='p' size={14} text_box>
                CODICE EAN:
              </Text>
              <Text as='p' size={14} style={{ marginLeft: '5px' }} text_box>
                {ean}
              </Text>
            </Flex>
          )}
          {mpn && mpn !== '0' && (
            <Flex wrap='nowrap' className='mb-50'>
              <Text bold upper as='p' size={14} text_box>
                CODICE MPN:
              </Text>
              <Text as='p' size={14} style={{ marginLeft: '5px' }} text_box>
                {mpn}
              </Text>
            </Flex>
          )}
          <Text bold as='h4' capit className='mb-20' text_box>
            {numberWithDots(points)} {is_salabam ? 'soglia punti' : pointsLabel}{' '}
            {has_fee && ' + ' + fee}
          </Text>
          {reviews_enabled && (
            <Flex align='center' justify='space-between' className='mb-20'>
              <Flex justify='center' align='center'>
                <StarRating
                  num={rating}
                  numRating={numRating}
                  setNumRating={setNumRating}
                  inSlider={inSlider}
                  readonly={true}
                  halfStar={true}
                />
                <Text as='p' style={{ marginLeft: '5px' }} text_box>
                  ({reviews?.length}){' '}
                </Text>
              </Flex>
            </Flex>
          )}
          {pathName !== '/user/options/ordini' && (
            <>
              {isCart &&
                (!is_salabam ? (
                  <Button
                    size={14}
                    padding='0 10px 0 25px'
                    style={{ backgroundImage: 'none' }}
                    className='mb-10 hoverBtn'
                    border
                    upper
                    fullwidth
                    loading={isLoadingCart}
                    disab
                    disabled={pathName === '/wishlist' ? false : inWishlist}
                    onClick={() => {
                      return pathName === '/catalog' || pathName === '/'
                        ? inCart
                          ? removeCartHandler()
                          : addCartHandler()
                        : pathName === '/wishlist'
                        ? moveToCartHandler(id)
                        : pathName === '/cart'
                        ? removeCartHandler(id)
                        : null;
                    }}
                  >
                    <Text as='p' upper type='textSlider' text_box size={12}>
                      {pathName === '/catalog' || pathName === '/'
                        ? inCart
                          ? 'Rimuovi dal CARRELLO'
                          : 'AGGIUNGI AL CARRELLO'
                        : pathName === '/wishlist'
                        ? 'Sposta nel carrello'
                        : pathName === '/cart'
                        ? 'Rimuovi dal carrello'
                        : ''}
                    </Text>
                    {inCart ? (
                      <RiDeleteBin2Line />
                    ) : option?.menuSettings?.cart?.icon_url ? (
                      <AddToCart className='maskIcon' src={option?.menuSettings?.cart?.icon_url} />
                    ) : (
                      <AiOutlineShoppingCart />
                    )}
                  </Button>
                ) : (
                  <>
                    <Button
                      size={14}
                      padding='0 10px 0 25px'
                      style={{ backgroundImage: 'none' }}
                      className='mb-10 hoverBtn'
                      border
                      upper
                      fullwidth
                      loading={salabamLoading}
                      onClick={salabamHandler}
                    >
                      <Text as='p' upper type='textSlider' size={12}>
                        {cta}
                      </Text>
                      <BsArrowRightCircle size='27px' />
                    </Button>
                    {accessToSalabam && (
                      <Text text_box className='mb-10'>
                        Non hai punti a sufficienza per acquistare il prodotto
                      </Text>
                    )}
                  </>
                ))}

              {isWishlist && (
                <Button
                  size={14}
                  padding='0 10px 0 25px'
                  style={{ backgroundImage: 'none' }}
                  border
                  upper
                  fullwidth
                  className='btns hoverBtn'
                  loading={isLoadingWish}
                  disabled={pathName === '/cart' ? false : inCart}
                  onClick={() =>
                    pathName === '/catalog' || pathName === '/'
                      ? inWishlist
                        ? removeFromWishlistHandler(id)
                        : addToWishlistHandler(id)
                      : pathName === '/wishlist'
                      ? removeFromWishlistHandler(id)
                      : pathName === '/cart'
                      ? moveToWishlistHandler(id)
                      : null
                  }
                >
                  <Text as='p' upper type='textSlider' text_box size={12}>
                    {pathName === '/catalog' || pathName === '/'
                      ? inWishlist
                        ? 'Rimuovi dai preferiti'
                        : 'Aggiungi ai preferiti'
                      : pathName === '/wishlist'
                      ? 'Rimuovi dai preferiti'
                      : pathName === '/cart'
                      ? 'Sposta nei preferiti'
                      : ''}
                  </Text>
                  {inWishlist ? (
                    <RemoveWishlistIcon style={{ width: '30px' }} />
                  ) : option?.menuSettings?.wishlist?.icon_url ? (
                    <AddToWishlist
                      className='maskIcon'
                      src={option?.menuSettings?.wishlist?.icon_url}
                    />
                  ) : (
                    <AiOutlineHeart />
                  )}
                </Button>
              )}
            </>
          )}
        </Col>
      </Flex>

      <Content horiz={horiz} inModal={inModal}>
        {date && (
          <Flex>
            <Text capit bold style={{ marginRight: '5px' }} text_box>
              data:
            </Text>{' '}
            <Text text_box>{moment(date).format('DD/MM/YYYY HH:MM')}</Text>
          </Flex>
        )}
        {status && (
          <Text capit text_box>
            status: {status}
          </Text>
        )}

        {description_top && (
          <Text size={16} style={{ marginTop: 0, marginBottom: 20 }} text_box>
            {description_top}
          </Text>
        )}

        {main_features?.length > 0 && (
          <Text bold as='p' capit size={16} text_box>
            caratteristiche principali :
          </Text>
        )}

        <Flex align='center' justify='space-between'>
          {main_features?.map(item => {
            return (
              <Flex width='49%' align='center' wrap='nowrap' style={{ marginBottom: '7px' }}>
                <Text bold as='p' size={16} text_box>
                  {item.label}:
                </Text>
                <Text as='p' size={16} style={{ marginLeft: '5px' }} text_box>
                  {item.value}
                </Text>
              </Flex>
            );
          })}
        </Flex>

        <Text as='p' capit size={14} text_box>
          {description}
        </Text>

        {tech_data?.length > 0 && (
          <>
            <Text
              as='div'
              size={16}
              upper
              bold
              text_click
              className='specifiche_tecniche'
              style={{ cursor: 'pointer' }}
              onClick={() => setIsTech(prev => !prev)}
              width='fit-content'
            >
              SPECIFICHE TECNICHE{' '}
              {isTech ? <KeyboardArrowUp size={20} /> : <KeyboardArrowDown size={20} />}
            </Text>
            {isTech && <Techs data={tech_data} />}
          </>
        )}
      </Content>
    </Container>
  );
}

export default ProductDetails;
